<template>
	<v-list style="width: 100%">
		<v-list-tile v-if="loading">
			<v-list-tile-action>
				<v-progress-circular color="primary" indeterminate />
			</v-list-tile-action>
			<v-list-tile-title v-t="'loading_progress'" />
		</v-list-tile>
		<CatalogThemeItem
			v-for="(catalogTheme, index) in catalogThemes"
			:key="catalogTheme.id"
			:value="catalogTheme"
			:permissions="permissions"
			@delete="deleteCatalogTheme(index)"
			@input="saveCatalogTheme($event, index)"
		/>
	</v-list>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

export default {
	name: 'CatalogThemesList',
	components: {
		CatalogThemeItem: () => ({
			component: import('@/components/SuperAdmin/CatalogThemes/CatalogThemeItem')
		})
	},
	mixins: [SuperAdminModuleGuard],
	props: {
		items: {
			default: () => ([]),
			required: false,
			type: Array
		},
		loading: {
			default: false,
			required: false,
			type: Boolean
		}
	},
	data: function () {
		return {
			permissions: []
		}
	},
	computed: {
		catalogThemes: function () {
			return this.items
		}
	},
	mounted: function () {
		this.loadPermissions()
	},
	methods: {
		createCatalogTheme: function (catalogTheme, index) {
			this.service.createCatalogTheme(catalogTheme).then(res => {
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('settings.messages.theme_created'))
				Object.assign(this.catalogThemes[index], res)
			})
		},
		deleteCatalogTheme: function (index) {
			this.catalogThemes = this.catalogThemes.filter((item, pos) => index !== pos)
		},
		loadPermissions: function () {
			this.service.getACLList()
				.then(permissions => {
					this.permissions = permissions.map(acl => ({
						text: this.$t(acl.title),
						value: acl.id
					}))
				})
		},
		removeCatalogThemeFromList: function (catalogThemeId) {
			this.catalogThemes = this.catalogThemes.filter(catalogTheme => catalogTheme.id !== catalogThemeId)
		},
		saveCatalogTheme: function (catalogTheme, index) {
			if (catalogTheme.id) {
				this.updateCatalogTheme(catalogTheme)
			} else {
				this.createCatalogTheme(catalogTheme, index)
			}
		},
		updateCatalogTheme: function (catalogTheme) {
			this.service.updateCatalogTheme(catalogTheme.id, catalogTheme).then(() => {
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('settings.messages.theme_updated'))
			})
		}
	}
}
</script>
